import { css } from "@emotion/react"
import { alpha } from "@mui/system"
import Theme from "appearance/Theme"

export class CollectionCardStyle {
  static CardWrapperStyle = css({
    backgroundColor: "#212121",
  })

  static CardContentWrapperStyle = css({
    padding: 0,
  })

  static CardTitleWrapperStyle = css({
    padding: Theme.spacing(2),
    textAlign: "center",
    border: "0px solid",
    borderBottomWidth: 4,
    borderBottomColor: alpha("#FFFFFF", 0.05),
  })

  static CardDescriptionWrapperStyle = css({
    padding: Theme.spacing(2),
    textAlign: "center",
    color: "#B4B4B4",
    height: "auto",
    fontSize: 16,
  })

  static ExpandWrapperStyle = css({
    textAlign: "center",
    margin: Theme.spacing(2, 0),
  })

  static CardActionsWrapperStyle = css({
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: Theme.spacing(4),
  })
}
