/** @jsxImportSource @emotion/react */
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField, Typography } from "@mui/material"
import { blue, green, orange, red, yellow } from "@mui/material/colors"
import Theme from "appearance/Theme"
import { useState } from "react"
import { useEventListener } from "utils/EventUtil"

const ActionsPopup = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [popupProps, setPopupProps] = useState<any>({})
  const [inputText, setInputText] = useState<string>()

  const preset = {
    success: {
      color: green[500],
      icon: "lnr-smile",
    },
    warning: {
      color: orange[500],
      icon: "lnr-warning",
    },
    error: {
      color: red[500],
      icon: "lnr-sad",
    },
    info: {
      color: blue[500],
      icon: "lnr-star",
    },
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setPopupProps({})
  }

  useEventListener("OpenActionsPopup", props => {
    setPopupProps(props)
    setOpenDialog(true)
  })

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{popupProps.title}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: Theme.spacing(3) }}>
          <Avatar sx={{ backgroundColor: preset[popupProps.status as any]?.color, marginBottom: Theme.spacing(3) }}>
            <Icon className={preset[popupProps.status as any]?.icon}></Icon>
          </Avatar>
          <Typography>{popupProps.message}</Typography>
          {popupProps.inputLabel && <TextField autoFocus margin="dense" placeholder={popupProps.inputLabel} fullWidth variant="outlined" onChange={event => setInputText(event.target.value)} />}
        </Box>
      </DialogContent>
      <DialogActions sx={{ flexDirection: { xs: "column", md: "row" }, justifyContent: "center" }} disableSpacing={true}>
        {popupProps.actions?.map((action, index) => (
          <Button
            sx={index > 0 ? { marginTop: { xs: 2, md: 0 }, marginLeft: { xs: 0, md: 1 } } : {}}
            onClick={() => {
              handleCloseDialog()
              action.onClick()
            }}
            variant="outlined"
            key={action.label}>
            {action.icon}
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}

export default ActionsPopup
