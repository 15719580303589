/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material"
import { Interpolation, Theme } from "@emotion/react"

type Props = {
  src: string | undefined
  alt: string | undefined
  width?: number | undefined
  height?: number | undefined
}

const Image = ({ src, alt, width = undefined, height = undefined }: Props) => {
  return <Box component="img" src={src} width={width} height={height} alt={alt} />
}

export default Image
