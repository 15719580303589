export const setItem = (key: string, value: any) => {
  if (process.browser) {
    const item = typeof value === "string" ? value : JSON.stringify(value)
    localStorage.setItem(key, item)
  }
}

export const getItem = (key: string) => {
  if (process.browser) {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  }
  return null
}
