import { StatusCodeEnum } from "services/api/enums/StatusCodeEnum"

export const ErrorInterceptor = (error: { status: StatusCodeEnum }) => {
  const { status } = error

  switch (status) {
    case StatusCodeEnum.InternalServerError: {
      // Handle InternalServerError
      break
    }
    case StatusCodeEnum.Forbidden: {
      // Handle Forbidden
      break
    }
    case StatusCodeEnum.Unauthorized: {
      // Handle Unauthorized
      break
    }
    case StatusCodeEnum.TooManyRequests: {
      // Handle TooManyRequests
      break
    }
  }

  return Promise.resolve(error)
}
