/** @jsxImportSource @emotion/react */
import { AppBar, Toolbar, Box, Typography, Button, Link } from "@mui/material"
import Image from "components/materials/Image"
import { FooterStyle } from "appearance"
import Theme from "appearance/Theme"

const Footer = () => {
  return (
    <Box style={{ flexGrow: 1 }}>
      <AppBar component="div" position="static" enableColorOnDark>
        <Toolbar css={FooterStyle.PrimaryAppBarStyle} sx={{ flexDirection: { xs: "column", md: "row" } }}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link href="/">
              <Image src="/assets/logo.svg" height={59} alt="logo" />
            </Link>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, marginBottom: Theme.spacing(2) }}>
            <Image src="/assets/logo.svg" height={39} alt="logo" />
          </Box>
          <Box sx={{ flexGrow: { md: 1 }, flexDirection: "row", display: "flex", justifyContent: { xs: "center", md: "flex-end" }, flexWrap: "wrap" }}>
            {/* <Button variant="text" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/about`}>
              <Typography variant="body1">About</Typography>
            </Button>
            <Box m={1} />
            <Button variant="text" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/blog`}>
              <Typography variant="body1">Blog</Typography>
            </Button>
            <Box m={1} />
            <Button variant="text" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/contact`}>
              <Typography variant="body1">Contact</Typography>
            </Button>
            <Box m={1} /> */}
            <Button variant="text" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/faq`}>
              <Typography variant="body1">FAQ</Typography>
            </Button>
            <Box m={1} />
            <Button variant="text" href="/privacy-policy">
              <Typography variant="body1">Privacy Policy</Typography>
            </Button>
            <Box m={1} />
            <Button variant="text" href="/terms-of-use">
              <Typography variant="body1">Terms of Use</Typography>
            </Button>
            <Box m={1} />
            <Button variant="text" href="/terms-of-sale">
              <Typography variant="body1">Terms of Sale</Typography>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" color="secondary" enableColorOnDark>
        <Toolbar css={FooterStyle.SecondaryAppBarStyle} sx={{ textAlign: { xs: "center" } }}>
          <Typography variant="caption">
            {new Date().getFullYear()} © Tribly, All Rights Reserved | Powered by{" "}
            <a href="https://thewhitgroup.com/" target="_blank" rel="noreferrer">
              thewhitgroup.com
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Footer
