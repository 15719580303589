/** @jsxImportSource @emotion/react */
import { AppBar, Toolbar, Box, IconButton, Button, Badge, Menu, MenuItem, Icon, Link, Avatar, Divider } from "@mui/material"
import Image from "components/materials/Image"
import SearchInput from "components/materials/SearchInput"
import { useCallback, useEffect, useState } from "react"
import { CommonStyle, NavigationBarStyle } from "appearance"
import { getProviders, signIn, signOut } from "next-auth/react"
import { EventEmitter } from "utils/EventUtil"
import { useShoppingCart } from "use-shopping-cart"
import { useRouter } from "next/router"
import { ethereumClient } from "services/wallet-connect/WalletConnectService"
import { confirmRole } from "services/api/modules/profile"

type Props = {
  currentRoute: String
  isAdmin: boolean
  isArtist: boolean
  isUser: boolean
  session: any
  status: string
  onSetLoading: (loading: boolean) => void
}

const NavigationBar = ({ currentRoute, isAdmin, isArtist, isUser, session, status, onSetLoading }: Props) => {
  const router = useRouter()

  const [providers, setProviders] = useState<any>(null)
  const { cartCount, clearCart } = useShoppingCart()

  useEffect(() => {
    if (!providers) {
      getProviders().then(providers => setProviders(providers))
    }
  }, [providers])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const confirmUserRole = useCallback(
    (isArtist: boolean) => {
      onSetLoading(true)
      confirmRole(isArtist).then(res => {
        if (isArtist) {
          document.location.href = "/collection/create"
        } else {
          onSetLoading(false)
        }
      })
    },
    [onSetLoading],
  )

  const openRolePopup = () => {
    EventEmitter.emit("OpenActionsPopup", {
      title: "Welcome!",
      message: "Are you interested in creating your own collection?",
      status: "info",
      actions: [
        {
          label: "No",
          onClick: () => confirmUserRole(false),
        },
        {
          label: "Yes",
          onClick: () => confirmUserRole(true),
        },
      ],
    })
    handleMenuClose()
  }

  const doSignOut = async () => {
    //await router.push("/")
    clearCart()
    await signOut()
    await ethereumClient.disconnect()
    document.location.href = `${process.env.NEXT_PUBLIC_VENLY_AUTH_URL}/auth/realms/Arkane/protocol/openid-connect/logout?redirect_uri=${process.env.NEXT_PUBLIC_SITE_URL}`
  }

  const menuId = "primary-search-account-menu"
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <Link underline="none" href="/profile">
        <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
      </Link>
      {isArtist && (
        <Link underline="none" href={session.collection.id ? "/collection/summary" : "/collection/create"}>
          <MenuItem onClick={handleMenuClose}>My Collectibles</MenuItem>
        </Link>
      )}
      {isUser && <MenuItem onClick={openRolePopup}>Create Artist Collection</MenuItem>}
      <MenuItem onClick={doSignOut}>Sign Out</MenuItem>
    </Menu>
  )

  const mobileMenuId = "primary-search-account-menu-mobile"
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {isAdmin
        ? [
            <Link underline="none" href="/admin/users" key={1}>
              <MenuItem onClick={handleMobileMenuClose}>Manage Users</MenuItem>
            </Link>,
            <Link underline="none" href="/admin/collections" key={2}>
              <MenuItem onClick={handleMobileMenuClose}>Manage Collections</MenuItem>
            </Link>,
            <Link underline="none" href="/admin/transactions" key={3}>
              <MenuItem onClick={handleMobileMenuClose}>Transactions</MenuItem>
            </Link>,
            <Link underline="none" href="/admin/wallets" key={4}>
              <MenuItem onClick={handleMobileMenuClose}>Company Wallets</MenuItem>
            </Link>,
          ].map(item => item)
        : [
            <Link underline="none" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}`} key={1}>
              <MenuItem onClick={handleMobileMenuClose}>Home</MenuItem>
            </Link>,
            <Link underline="none" href="/" key={2}>
              <MenuItem onClick={handleMobileMenuClose}>Collectibles</MenuItem>
            </Link>,
            // <Link underline="none" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/records`} key={3}>
            //   <MenuItem onClick={handleMobileMenuClose}>Records</MenuItem>
            // </Link>,
            // <Link underline="none" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/contact`} key={4}>
            //   <MenuItem onClick={handleMobileMenuClose}>Contact</MenuItem>
            // </Link>,
          ].map(item => item)}
      <Divider />
      {!session
        ? providers &&
          Object.values(providers).map((provider: any, index: number) =>
            index === 0 ? (
              <MenuItem onClick={() => signIn(provider.id, undefined, { prompt: "login" })} key={9 + index}>
                Sign In
              </MenuItem>
            ) : (
              <></>
            ),
          )
        : [
            <Link underline="none" href="/profile" key={7}>
              <MenuItem onClick={handleMobileMenuClose}>My Profile</MenuItem>
            </Link>,
            isArtist && (
              <Link underline="none" href={session.collection.id ? "/collection/summary" : "/collection/create"} key={8}>
                <MenuItem onClick={handleMobileMenuClose}>My Collectibles</MenuItem>
              </Link>
            ),
            isUser && (
              <MenuItem onClick={openRolePopup} key={9}>
                Create Artist Collection
              </MenuItem>
            ),
            <MenuItem onClick={doSignOut} key={9}>
              Sign Out
            </MenuItem>,
          ]}
    </Menu>
  )

  return (
    <Box style={{ flexGrow: 1 }}>
      <AppBar position="static" enableColorOnDark>
        {status === "loading" ? (
          <Toolbar css={CommonStyle.BlackBackgroundStyle}></Toolbar>
        ) : (
          <Toolbar css={CommonStyle.BlackBackgroundStyle}>
            <Link href="/">
              <Image src="/assets/logo.svg" alt="logo" />
            </Link>
            <Box css={NavigationBarStyle.LinkWrapperStyle} sx={{ display: { xs: "none", md: "flex" } }}>
              {isAdmin ? (
                <>
                  <Button variant="text" color="inherit" href="/admin/users" sx={{ borderRadius: 0, borderBottom: currentRoute === "/admin/users" ? "1px solid" : undefined }}>
                    Users
                  </Button>
                  <Button variant="text" color="inherit" href="/admin/collections" sx={{ borderRadius: 0, borderBottom: currentRoute === "/admin/collections" ? "1px solid" : undefined }}>
                    Collectibles
                  </Button>
                  <Button variant="text" color="inherit" href="/admin/transactions" sx={{ borderRadius: 0, borderBottom: currentRoute === "/admin/transactions" ? "1px solid" : undefined }}>
                    Transactions
                  </Button>
                  <Button variant="text" color="inherit" href="/admin/wallets" sx={{ borderRadius: 0, borderBottom: currentRoute === "/admin/wallets" ? "1px solid" : undefined }}>
                    Company Wallets
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="text" color="inherit" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}`} sx={{ borderRadius: 0 }}>
                    Home
                  </Button>
                  <Button variant="text" color="inherit" href="/" sx={{ borderRadius: 0 }}>
                    <Box sx={{ borderBottom: currentRoute === "/" ? "1px solid" : undefined }}>Collectibles</Box>
                  </Button>
                  {/* <Button variant="text" color="inherit" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/records`} sx={{ borderRadius: 0 }}>
                    Records
                  </Button>
                  <Button variant="text" color="inherit" href={`${process.env.NEXT_PUBLIC_HOME_SITE_URL}/contact`} sx={{ borderRadius: 0 }}>
                    Contact
                  </Button> */}
                </>
              )}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
            <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: { xs: "none", md: "flex" } }}>
              {/* <SearchInput onSearch={keyword => router.push(`/collections?keyword=${keyword}`)} /> */}
            </Box>
            <Box css={NavigationBarStyle.IconButtonWrapperStyle}>
              {/* <IconButton href="/cart" css={NavigationBarStyle.IconButtonStyle} size="large" aria-label="show cart items" color="inherit">
                <Badge badgeContent={cartCount} color="accent" css={NavigationBarStyle.BadgeStyle} showZero>
                  <Icon className="lnr-cart" sx={{ fontSize: 25 }}></Icon>
                </Badge>
              </IconButton> */}

              {!session ? (
                <>
                  {providers &&
                    Object.values(providers).map((provider: any, index: number) =>
                      index === 0 ? (
                        <Button sx={{ display: { xs: "none", md: "inline-flex" } }} onClick={() => signIn(provider.id)}>
                          Sign in
                        </Button>
                      ) : (
                        <></>
                      ),
                    )}
                </>
              ) : (
                <IconButton
                  sx={{ display: { xs: "none", md: "inline-flex" } }}
                  css={NavigationBarStyle.IconButtonStyle}
                  size="large"
                  edge="end"
                  aria-label="open account menu"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit">
                  <Avatar alt={session.user.name} src={session.user.image} sx={{ width: 30, height: 30 }} />
                </IconButton>
              )}

              <IconButton sx={{ display: { md: "none" } }} size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                <Icon className="lnr-menu" sx={{ fontSize: 25 }}></Icon>
              </IconButton>
            </Box>
          </Toolbar>
        )}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  )
}

export default NavigationBar
