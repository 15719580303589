import { css } from "@emotion/react"
import { alpha } from "@mui/material"
import Theme from "appearance/Theme"

export class CommonStyle {
  static OverlayStyle = css({
    backgroundColor: alpha(Theme.palette.black.main, 0.65),
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })

  static CenterStyle = css({
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  })

  static ThreeDotsStyle = css({
    fontSize: 50,
    lineHeight: 0,
    color: Theme.palette.divider,
    marginBottom: Theme.spacing(5),
  })

  static BlackBackgroundStyle = css({
    backgroundColor: Theme.palette.black.main,
  })

  static PrimaryBackgroundStyle = css({
    backgroundColor: Theme.palette.secondary.main,
  })

  static PrimarySecondaryStyle = css({
    backgroundColor: Theme.palette.primary.main,
  })

  static ActionGreyStyle = css({
    marginLeft: 0,
    marginRight: 12,
    width: 28,
    height: 28,
    backgroundColor: Theme.palette.gray.main,

    "&:hover": {
      backgroundColor: Theme.palette.gray.main,
    },

    "& span": {
      fontSize: "0.9rem",
    },
  })

  static Square = css({
    width: "100%",
    height: 0,
    paddingTop: "100%",
    backgroundSize: "cover",
    borderRadius: "5px",
  })

  static GenericBorderRadius = css({
    borderRadius: "5px",
    overflow: "hidden",
  })
}
