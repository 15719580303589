import { css } from "@emotion/react"
import Theme from "appearance/Theme"

export class AdminUsersPageStyle {
  static FeaturedBoxStyle = css({
    backgroundColor: Theme.palette.primary.main,
    height: 200,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  })

  static MainContainerStyle = css({
    padding: Theme.spacing(8, 0),
    display: "flex",
    alignItems: "stretch",
    position: "relative",
  })
}
