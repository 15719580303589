/** @jsxImportSource @emotion/react */
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField, Typography } from "@mui/material"
import { blue, green, orange, red, yellow } from "@mui/material/colors"
import Theme from "appearance/Theme"
import { useState } from "react"
import { useEventListener } from "utils/EventUtil"

const Popup = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [popupProps, setPopupProps] = useState<any>({})
  const [inputText, setInputText] = useState<string>()

  const preset = {
    success: {
      color: green[500],
      icon: "lnr-smile",
    },
    warning: {
      color: orange[500],
      icon: "lnr-warning",
    },
    error: {
      color: red[500],
      icon: "lnr-sad",
    },
    info: {
      color: blue[500],
      icon: "lnr-star",
    },
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setPopupProps({})
  }

  const handleOk = () => {
    handleCloseDialog()
    if (popupProps.onOk) {
      popupProps.onOk(inputText)
    }
  }

  const handleCancel = () => {
    handleCloseDialog()
    if (popupProps.onCancel) {
      popupProps.onCancel()
    }
  }

  useEventListener("OpenPopup", props => {
    setPopupProps(props)
    setOpenDialog(true)
  })

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{popupProps.title}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: Theme.spacing(3) }}>
          <Avatar sx={{ backgroundColor: preset[popupProps.status as any]?.color, marginBottom: Theme.spacing(3) }}>
            <Icon className={preset[popupProps.status as any]?.icon}></Icon>
          </Avatar>
          <Typography>{popupProps.message}</Typography>
          {popupProps.inputLabel && <TextField autoFocus margin="dense" placeholder={popupProps.inputLabel} fullWidth variant="outlined" onChange={event => setInputText(event.target.value)} />}
        </Box>
      </DialogContent>
      <DialogActions sx={{ flexDirection: { xs: "column", md: "row" } }} disableSpacing={true}>
        <Button onClick={handleCancel} variant="outlined">
          {popupProps.cancelLabel || "Cancel"}
        </Button>
        <Button onClick={handleOk} sx={{ marginTop: { xs: 2, md: 0 }, marginLeft: { xs: 0, md: 1 } }}>
          {popupProps.okLabel || "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Popup
