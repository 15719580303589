import { EventEmitter } from "events"
import { useEffect } from "react"

const eventEmitter = new EventEmitter()
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const innerHandler = (...args) => handler(...args)
    if (eventName) {
      eventEmitter.on(eventName, innerHandler)
    }
    return () => {
      eventEmitter.off(eventName, innerHandler)
    }
  }, [handler, eventName])
}
export { eventEmitter as EventEmitter, useEventListener }
