import { css } from "@emotion/react"

export class TierIconStyle {
  static IconStyle = css({
    width: 28,
    height: 28,

    ".MuiIcon-root": {
      fontSize: 12,
    },
  })

  static SmallIconStyle = css({
    width: 18,
    height: 18,

    ".MuiIcon-root": {
      fontSize: 8,
    },
  })
}
