import { css } from "@emotion/react"
import { alpha } from "@mui/material"
import Theme from "appearance/Theme"

export class CollectionsPageStyle {
  static FeaturedBoxStyle = css({
    backgroundColor: Theme.palette.primary.main,
    height: 200,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  })

  static FeaturedContentStyle = css({
    height: 319,
    width: 443,
    backgroundColor: alpha(Theme.palette.accent.main, 0.85),
    display: "flex",
    alignItems: "center",
    padding: 80,
    zIndex: 1,

    "& p": {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "35px",
      color: "white",
      textTransform: "uppercase",
    },
  })

  static FeaturedImageStyle = css({
    backgroundImage: "url('/assets/home_featured_image.jpg')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 319,
    width: 443,
    zIndex: 1,
  })

  static TitleWrapperStyle = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: Theme.spacing(4),
  })

  static SortWrapperStyle = css({
    display: "flex",
    alignItems: "center",
    padding: Theme.spacing(4.5, 0),
  })

  static CollectionGridStyle = css({
    padding: Theme.spacing(6, 0),
  })
}
