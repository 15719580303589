import { css } from "@emotion/react"
import { alpha } from "@mui/system"
import Theme from "appearance/Theme"

export class NftCardStyle {
  static CardWrapperStyle = css({
    position: "relative",
    width: "100%",
    height: "100%",
  })

  static CardContentWrapperStyle = css({
    padding: 0,

    "&:last-child": {
      paddingBottom: Theme.spacing(1),
    },
  })

  static CardTitleWrapperStyle = css({
    padding: Theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "0px solid",
    borderBottomWidth: 4,
    borderBottomColor: alpha("#FFFFFF", 0.05),
  })

  static CardInfoRowStyle = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: Theme.spacing(1, 2),
  })

  static CardActionsWrapperStyle = css({
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: Theme.spacing(4),
  })

  static PriceLabelStyle = css({
    fontSize: 10,
    color: Theme.palette.accent.main,
    textTransform: "uppercase",
  })

  static CurrencyStyle = css({
    fontSize: 10,
    color: alpha("#FFFFFF", 0.85),
    textTransform: "uppercase",
  })

  static EditButtonStyle = css({
    width: 28,
    height: 28,
    position: "absolute",
    top: Theme.spacing(1),
    right: Theme.spacing(1),
  })
}
