import { ApiService } from "services/api/ApiService"

export const updateProfileWallets = async (data?: any): Promise<any> => {
  const response = await ApiService.put<any, any>("/profile", data)
  return response
}

export const updateProfile = async (data?: any): Promise<any> => {
  const response = await ApiService.put<any, any>("/profile/update", data)
  return response
}

export const agreeArtistLegal = async (): Promise<any> => {
  const response = await ApiService.put<any, any>("/profile/agree")
  return response
}

export const confirmRole = async (isArtist: boolean): Promise<any> => {
  const response = await ApiService.put<any, any>("/profile/role", { isArtist })
  return response
}
