import { css } from "@emotion/react"
import { alpha, borderBottom } from "@mui/system"
import Theme from "appearance/Theme"

export class NftDetailsCardStyle {
  static CardWrapperStyle = css({
    padding: Theme.spacing(6),
  })

  static CardContentWrapperStyle = css({
    padding: 0,

    "&:last-child": {
      paddingBottom: Theme.spacing(1),
    },
  })

  static CardTitleWrapperStyle = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  })

  static CardInfoRowStyle = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: Theme.spacing(2, 0),

    "&:last-child": {
      paddingBottom: 0,
    },
  })

  static CardInfoColumnStyle = css({
    flexDirection: "column",
    padding: Theme.spacing(1, 0),
  })

  static CardDescriptionWrapperStyle = css({
    paddingBottom: Theme.spacing(2),
  })

  static CardDescriptionStyle = css({
    color: "white",
    fontFamily: "Roboto",
  })

  static ExpandWrapperStyle = css({})

  static CardActionsWrapperStyle = css({
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: Theme.spacing(4),
  })

  static PropertyLabelStyle = css({
    fontSize: 10,
    color: Theme.palette.accent.main,
    textTransform: "uppercase",
  })

  static CurrencyStyle = css({
    fontSize: 10,
    color: alpha("#FFFFFF", 0.85),
    textTransform: "uppercase",
  })

  static TierWrapperStyle = css({
    display: "inline-flex",
    alignItems: "center",
  })

  static TierTextStyle = css({
    textTransform: "uppercase",
    lineHeight: 1,
    marginLeft: Theme.spacing(1),
  })

  static DividerStyle = css({
    borderBottomWidth: 2,
    borderBottomColor: alpha("#FFFFFF", 0.1),
  })

  static ListItemStyle = css({
    padding: Theme.spacing(1, 0),
  })
}
