import { css } from "@emotion/react"
import { alpha } from "@mui/material"
import Theme from "appearance/Theme"

export class CollectionPageStyle {
  static FeaturedBoxStyle = css({
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 260,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  })

  static CollectionInfoWrapperStyle = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: Theme.spacing(7),
  })

  static CollectionInfoGridStyle = css({
    alignItems: "center",
  })

  static CollectionInfoActionsStyle = css({
    marginTop: Theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  })

  static ActionAccentStyle = css({
    marginLeft: 0,
    marginRight: 12,
    width: 28,
    height: 28,
    backgroundColor: Theme.palette.accent.main,

    "&:hover": {
      backgroundColor: Theme.palette.accent.main,
    },

    "& span": {
      fontSize: "0.9rem",
    },
  })

  static AvatarGridItemStyle = css({
    display: "flex",
    justifyContent: "flex-end",
  })

  static AvatarStyle = css({
    border: "5px solid",
    borderRadius: "5px",
  })

  static TiresWrapperStyle = css({
    backgroundColor: "#151515",
    padding: Theme.spacing(6, 0),
  })

  static TiresContainerStyle = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  })

  static SortWrapperStyle = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: Theme.spacing(4.5, 0),
  })

  static RadioFormControlStyle = css({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  })

  static RadioFormLabelStyle = css({
    fontSize: 14,
    marginRight: Theme.spacing(3),
  })

  static NftsGridStyle = css({
    padding: Theme.spacing(6, 0),
  })
}
