import { css } from "@emotion/react"
import { alpha } from "@mui/material"
import Theme from "appearance/Theme"

export class ProfilePageStyle {
  static FeaturedBoxStyle = css({
    backgroundImage: "url('/assets/profile-hero-bg.jpg')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 260,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  })

  static BannerContainerStyle = css({
    padding: Theme.spacing(8, 0),
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100%",
  })

  static MainContainerStyle = css({
    padding: Theme.spacing(8, 0),
  })

  static TabsContainerStyle = css({
    paddingBottom: Theme.spacing(8),
  })

  static AvatarStyle = css({
    border: "4px solid",
    width: 180,
    height: 180,
  })

  static TabHeader = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: Theme.spacing(4),
  })
}
