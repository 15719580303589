import { css } from "@emotion/react"
import Theme from "appearance/Theme"

export class TierPaperStyle {
  static PaperStyle = css({
    padding: Theme.spacing(3, 2),
    height: "auto",
    position: "relative",
    overflow: "hidden",
    borderRadius: 5,
  })

  static IconStyle = css({
    width: 28,
    height: 28,

    ".MuiIcon-root": {
      fontSize: 12,
    },
  })

  static DescriptionWrapperStyle = css({
    height: "auto",
    overflow: "hidden",
    fontSize: 13,
    color: "#A3A3A3",
  })

  static DescriptionStyle = css({
    fontSize: 13,
    overflowWrap: "break-word",
    fontFamily: "Roboto",
  })

  static ExpandButtonStyle = css({
    width: 32,
    height: 32,
    position: "absolute",
    bottom: 0,
    right: 0,

    "&:before": {
      content: "''",
      width: 0,
      height: 0,
      borderWidth: "0 0 32px 32px",
      borderColor: "transparent transparent #992DFC transparent",
      borderStyle: "solid",
      position: "absolute",
    },

    ".MuiIcon-root": {
      fontSize: 12,
      fontWeight: "bold",
      position: "absolute",
      bottom: 4,
      right: 4,
      transform: "rotate(45deg)",
    },
  })

  static CollapseButtonStyle = css({
    width: 32,
    height: 32,
    position: "absolute",
    bottom: 0,
    right: 0,

    "&:before": {
      content: "''",
      width: 0,
      height: 0,
      borderWidth: "0 0 32px 32px",
      borderColor: "transparent transparent #992DFC transparent",
      borderStyle: "solid",
      position: "absolute",
    },

    ".MuiIcon-root": {
      fontSize: 12,
      fontWeight: "bold",
      position: "absolute",
      bottom: 4,
      right: 4,
      transform: "rotate(90deg)",
    },
  })

  static EditButtonStyle = css({
    width: 28,
    height: 28,
    position: "absolute",
    top: Theme.spacing(1),
    right: Theme.spacing(1),
  })

  static EnableSwitchtyle = css({
    position: "absolute",
    top: Theme.spacing(1),
    right: Theme.spacing(1),
  })
}
