import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { DefaultHeaders } from "services/api/headers/DefaultHeader"
import { AuthenticationInterceptor } from "services/api/interceptors/AuthenticationInterceptor"
import { ErrorInterceptor } from "services/api/interceptors/ErrorInterceptor"

class ApiService {
  private instance: AxiosInstance | null = null

  private get apiService(): AxiosInstance {
    return this.instance != null ? this.instance : this.getInstance()
  }

  getInstance(): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_SITE_URL}/api`,
      headers: DefaultHeaders,
      withCredentials: true,
    })

    axiosInstance.interceptors.request.use(AuthenticationInterceptor, error => Promise.reject(error))

    axiosInstance.interceptors.response.use(
      response => response.data,
      error => {
        const { response } = error
        return ErrorInterceptor(response)
      },
    )

    this.instance = axiosInstance
    return axiosInstance
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.apiService.request(config)
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.apiService.get<T, R>(url, config)
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.apiService.post<T, R>(url, data, config)
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.apiService.put<T, R>(url, data, config)
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.apiService.delete<T, R>(url, config)
  }
}

const apiService = new ApiService()
export { apiService as ApiService }
