import { css } from "@emotion/react"

export class LayoutStyle {
  static ContainerStyle = css({
    backgroundColor: "black",
    minHeight: "calc(100vh - 356px)",
    display: "flex",
    flexDirection: "column",
  })
}
