import { css } from "@emotion/react"
import { alpha } from "@mui/material"
import Theme from "appearance/Theme"

export class FooterStyle {
  static PrimaryAppBarStyle = css({
    height: 186,
    justifyContent: "center",
    backgroundColor: Theme.palette.primary.main,
  })

  static SecondaryAppBarStyle = css({
    height: 90,
    justifyContent: "flex-start",
    backgroundColor: Theme.palette.secondary.main,
  })

  static LogoStyle = css({
    height: 59,
  })
}
