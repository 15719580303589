import { AxiosRequestConfig } from "axios"
import { getItem } from "utils/StorageUtil"

export const AuthenticationInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const venlyAuth = getItem("v-auth")

    if (venlyAuth != null && config.headers) {
      config.headers["v-authorization"] = `${venlyAuth.token}`
    }

    return config
  } catch (error) {
    throw new Error(error as any)
  }
}
