import { css } from "@emotion/react"
import { colors } from "@mui/material"
import Theme from "appearance/Theme"

export class NavigationBarStyle {
  static IconButtonWrapperStyle = css({
    alignItems: "center",
    justifyContent: "space-between",
  })

  static LinkWrapperStyle = css({
    margin: "0px 40px",

    "& .MuiButton-root": {
      fontSize: 14,
      lineHeight: "19px",
    },
  })

  static IconButtonStyle = css({})

  static BadgeStyle = css({
    "& .MuiBadge-badge": {
      height: 14,
      minWidth: 14,
      fontSize: 8,
      fontWeight: 600,
    },
  })
}
