/** @jsxImportSource @emotion/react */
import Head from "next/head"
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material"
import { ReactNode, useState } from "react"
import Footer from "./Footer"
import NavigationBar from "./NavigationBar"
import { CommonStyle, LayoutStyle } from "appearance"
import { useRouter } from "next/router"
import { useSession } from "next-auth/react"
import Popup from "./materials/Popup"
import ActionsPopup from "./materials/ActionsPopup"

type Props = {
  children?: ReactNode
  title?: string
}

const Layout: React.FC<Props> = ({ children, title = "" }) => {
  const router = useRouter()
  const isAdminPage = router.pathname.toLocaleLowerCase().indexOf("/admin") > -1
  const isArtistPages =
    router.pathname.toLocaleLowerCase().indexOf("/edit") > -1 || router.pathname.toLocaleLowerCase().indexOf("/create") > -1 || router.pathname.toLocaleLowerCase().indexOf("/summary") > -1

  const { data: session, status }: any = useSession()
  const [loading, setLoading] = useState(false)
  const role = session?.user?.role
  const isAdmin = role === "ADMIN"
  const isArtist = role === "ARTIST"
  const isUser = role === "USER"

  return (
    <>
      <Head>
        {/*  Global site tag (gtag.js) - Google Analytics --> */}
        {process.env.NEXT_PUBLIC_ENV === "production" && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-226711564-1"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'UA-226711564-1');
          `,
              }}
            />
          </>
        )}
        <title>{`${title} | Tribly`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="stylesheet" href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css"></link>
      </Head>

      <>
        <header>
          <NavigationBar currentRoute={router.pathname} isAdmin={isAdmin} isArtist={isArtist} isUser={isUser} session={session} status={status} onSetLoading={setLoading} />
        </header>

        <Box css={LayoutStyle.ContainerStyle}>
          {status === "loading" || loading ? (
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
              <CircularProgress />
            </Backdrop>
          ) : (isAdminPage && !isAdmin) || (isArtistPages && !isArtist && !isAdmin) ? (
            <Box css={CommonStyle.CenterStyle}>
              <Typography variant="h1">Access Denied</Typography>
              <Typography variant="body1">You need to be authenticated to view this page.</Typography>
            </Box>
          ) : (
            <>{children}</>
          )}
        </Box>

        <footer>
          <Footer />
        </footer>

        <Popup></Popup>
        <ActionsPopup></ActionsPopup>
      </>
    </>
  )
}

export default Layout
